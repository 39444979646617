$font-size: calc(0.8rem + 0.3vw) !default;
$font-weight: 400 !default;
$heading-weight: 600 !default;
$line-height: 1.6 !default;
$link-color: #68f !default;
$dark: #1c1d22 !default;
$light: #ffffff !default;

@function reduce ($percent) {
  @return rgba(mix($dark, $light), $percent / 100); }
